import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rikkart Lauten was the middle child of the `}<a parentName="p" {...{
        "href": "/Lauten%20Family",
        "title": "Lauten Family"
      }}>{`Lauten Family`}</a>{`, and was seen by many as the greatest `}<a parentName="p" {...{
        "href": "/Acronist",
        "title": "Acronist"
      }}>{`Acronist`}</a>{` ever seen at the `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{`. He had an older brother, `}<a parentName="p" {...{
        "href": "/Alrik%20Lauten",
        "title": "Alrik Lauten"
      }}>{`Alrik Lauten`}</a>{` and a younger sister, `}<a parentName="p" {...{
        "href": "/Vera%20Lauten",
        "title": "Vera Lauten"
      }}>{`Vera Lauten`}</a>{`. His Father and Mother were `}<a parentName="p" {...{
        "href": "/Halver%20Lauten",
        "title": "Halver Lauten"
      }}>{`Halver Lauten`}</a>{` and `}<a parentName="p" {...{
        "href": "/Elske%20Lauten",
        "title": "Elske Lauten"
      }}>{`Elske Lauten`}</a>{`. At the age of eighteen, he became a `}<a parentName="p" {...{
        "href": "/Fade",
        "title": "Fade"
      }}>{`Fade`}</a>{` due to an accident that occured while attending `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{`. In recent years, Rikkart has not been seen in public often.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      